.title{
    font-size:18px;
}
.table {
  border: 0px solid #ddd; 
  margin: 2% 0%;
}

.table .cell {
  border-bottom: 1px solid #ddd; 
}
  
.cell {
  text-align: left;
  background: #f4c007;
  padding: 8px;
}

.cell:hover {
  background: #6abfec; 
  cursor: pointer;
}

.table-container {
  display: flex;
  justify-content: center;  
}

.table {
  width: 100%;
  max-width: 1200px;  
}

.category {
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
}

.optedCategory {
  background-color: #E6E600;
}

.unoptedCategory {
  background-color: #C70039;
  color:white;
}
.question{    
  color:#4D9ABC;
  font-size:16px;    
  margin-top: 10px;
}

.option{
    color:#007684
}
.option{
    margin-top: -5px;
    margin-left: 10px;
}
.achieved {
  background-color: #00CD00;
  color:white;
  
}