.category{
    color:#00919E
}
.question{    
    color:#4D9ABC;
    font-size:16px;    
}
.option{
    color:#007684
}
.option span{
    margin-top: -5px;
    margin-left: 10px;
}