.row{
  margin-top: 10px !important;
}
.option{
  margin-top: 5px;
  color:#007684
}
.question{    
  color:#4D9ABC;
  font-size:16px;    
  margin-top: 10px;
}
