.wordcloud {
    display: flex;
    flex-direction: column;
    user-select: none;
}
.wordcloud svg {
    margin: 1rem 0;
    cursor: pointer;
}

.wordcloud label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-right: 8px;
}
.wordcloud textarea {
    min-height: 100px;
}